<template>
  <v-container fluid>
    <forms-index/>
  </v-container>
</template>

<script>

export default {
  name: 'Forms',
  components: {
    FormsIndex: () => import('@/components/forms/FormsIndex.vue'),
  },
}
</script>

<style scoped>

</style>